document.addEventListener('turbolinks:load', function() {
    setTimeout(function() {
        const adElement = document.querySelector('.adsbygoogle');
        const adLoaded = adElement && adElement.offsetHeight > 0;

        if (!adLoaded) {
            const adElementsToHide = [
                'ad-leaderboard',
                'ad-square',
                'ad-mid-banner',
                'ad-ephem',
                'ad-love',
                'ad-weekly',
                'ad-daily'
            ];

            const inHouseAdElementsToShow = [
                'in-house-square',
                'in-house-leaderboard',
                'in-house-mid-banner',
                'in-house-ephem-banner',
                'in-house-love-banner',
                'in-house-ad-weekly',
                'in-house-ad-daily'
            ];

            // Hide ad elements
            adElementsToHide.forEach(id => setDisplayStyle(id, 'none'));

            // Show in-house ad elements
            inHouseAdElementsToShow.forEach(id => setDisplayStyle(id, 'block'));

            if (document.getElementById('object_container')) {
                loadScript('object_container', 'https://pt.potwmora.com/embed/lf?c=object_container&site=oranum&cobrandId=&psid=astrolis&pstool=608_1&psprogram=revs&campaign_id=&forcedPsychics[]=&vp[showChat]=&vp[showCallToAction]=true&vp[showPerformerName]=true&vp[showPerformerStatus]=true&ms_notrack=1', function() {
                    console.log('Script loaded successfully:', 'https://');
                });
            }

            if (document.getElementById('oranum_livefeed_container')) {
                loadScript('oranum_livefeed_container', 'https://pt.potwmora.com/embed/livefeed/017?site=oranum&psid=astrolis&pstool=600_017&psprogram=revs&campaign_id=118306&subaffid={SUBAFFID}&ms_notrack=1', function() {
                    console.log('Script loaded successfully:', 'https://');
                });
            }

            if (document.getElementById('awe-matchmaker-container')) {
                var iframe = document.getElementById('awe-matchmaker-container');
                iframe.src = "https://pt.potwmora.com/match?psid=astrolis&psprogram=revs&pstool=610_1&cobrandid=&campaign_id=";
            }
        }
    }, 1000); 
});

function setDisplayStyle(elementId, displayStyle) {
    const element = document.getElementById(elementId);
    if (element) {
        element.style.display = displayStyle;
    }
}

function loadScript(containerId, scriptSrc, callback) {
    const container = document.getElementById(containerId);
    if (container) {
        const script = document.createElement('script');
        script.src = scriptSrc;
        script.onload = callback;
        container.appendChild(script);
    }
}